import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "../features/locations/activeLocationSlice";
import locationsReducer from "../features/locations/locationsSlice";
import usersReducer from "../features/users/usersSlice";
import notificationsReducer from "@features/notifications/notificationsSlice";
import tableEventsReducer from "@features/events/tableEventsSlice";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: {
    notifications: notificationsReducer,
    locations: locationsReducer,
    activeLocation: locationReducer,
    users: usersReducer,
    tableEvents: tableEventsReducer,
  },
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export type RootState = ReturnType<typeof store.getState>

export default store;

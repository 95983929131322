import { TOKEN_KEY } from "@hooks/useJwt.hook";

export const handle401 = async (response: Response) => {

    if (response.status === 401) {
        localStorage.removeItem(TOKEN_KEY);

        window.location.href = '/';
    }

    return response;
};

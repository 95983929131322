import { useJwt } from "@hooks/useJwt.hook";
import { handle401 } from "@lib/utils";

export enum Roles {
    MANAGER = 'MANAGER',
    ADMIN = 'ADMIN'
}

export interface User {
    id?: string; // uuid
    name: string;
    email: string;
    picture_url: string;
    joined?: string;
    role: Roles;
    // ids of locations where user has access - only useful for managers
    locations?: Array<string>;
    last_login?: string;
}

export interface CreateUser {
    email: string;
    role: Roles;
    locations: Array<string>;
}

const baseUrl = `${process.env.API_URL}/users`;

export const usersAPI = {
    async getAll(): Promise<Array<User>> {
        const token = useJwt();

        const response = await fetch(baseUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(handle401);

        return response.json();
    },

    async addUser(user: CreateUser): Promise<void> {
        const token = useJwt();

        const response = await fetch(baseUrl, {
            method: 'post',
            body: JSON.stringify(user),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(handle401);

        if (response.status === 409) {
            throw new Error('User has already been invited to the app');
        }

        if (response.status !== 201) {
            throw new Error('Something went wrong when creating new user');
        }

    },

    async delete(id: string): Promise<void> {

        const token = useJwt();

        const response = await fetch(`${baseUrl}/${id}`, {
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(handle401);

        if (response.status !== 204) {
            throw new Error('Something went wrong whilst deleting new user');
        }
    }
}

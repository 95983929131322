import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { locationsAPI } from "@lib/locationsAPI";
import { Table } from "@lib/tablesAPI";

export const fetchLocationById = createAsyncThunk(
    'locations/fetchLocationByIdStatus',
    async (locationId: string, thunkAPI) => {
      return await locationsAPI.fetchById(locationId);
    }
);

interface ActiveLocationSliceState {
  id: string;
  loading?: string;
  name: string;
  tables: Array<Table>;
}

const initialState: ActiveLocationSliceState = {
  id: 'DEFAULT_ID_REDUX',
  loading: 'idle',
  name: 'UNSET',
  tables: []
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    add: (state, action) => {
      state.tables.push(action.payload)
    },
    remove: (state, action) => {
      state.tables = state.tables.filter(table => !(table.id === action.payload.id && table.name === action.payload.name))
    }
  },
  extraReducers: (builder) => {

    builder.addCase(fetchLocationById.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.tables = action.payload.tables;
      state.id = action.payload.id;
    });

  }
})

// Action creators are generated for each case reducer function
export const { add, remove } = locationSlice.actions;

export default locationSlice.reducer;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableEventResponseDto, tableEventsAPI, TableEventStatus } from "@lib/tableEventsAPI";

interface TableEventsSliceState {
    locationId?: string;
    events: Array<TableEventResponseDto>;
    eventsByStatus: EventsMap;
}

type EventsMap = Record<TableEventStatus, Array<TableEventResponseDto>>;

const createTableEventsMap = (events: Array<TableEventResponseDto>): EventsMap => {
    return {
        [TableEventStatus.DEFAULT]: events.filter(event => event.status === TableEventStatus.DEFAULT),
        [TableEventStatus.FIRE_UNCONFIRMED]: events.filter(event => event.status === TableEventStatus.FIRE_UNCONFIRMED),
        [TableEventStatus.FIRE_CONFIRMED]: events.filter(event => event.status === TableEventStatus.FIRE_CONFIRMED),
        [TableEventStatus.COUNTDOWN_INIT]: events.filter(event => event.status === TableEventStatus.COUNTDOWN_INIT),
        [TableEventStatus.RESET]: [], // This is not used at the moment
    }
};

const initialState: TableEventsSliceState = {
    locationId: '',
    events: [],
    eventsByStatus: createTableEventsMap([]),
};

export interface EventsUpdate {
    locationId: string;
    events: Array<TableEventResponseDto>;
}

export const fetchTableEventsByLocationId = createAsyncThunk(
    'api/getTableEvents',
    async (locationId: string) => {
        return await tableEventsAPI.getEventsAtLocation(locationId);
    }
);


const tableEventsSlice = createSlice({
    name: 'table-events',
    initialState,
    reducers: {
        updateTableEventsState: (state, action: PayloadAction<EventsUpdate>) => {
            state.locationId = action.payload.locationId;
            state.events = action.payload.events;
            state.eventsByStatus = createTableEventsMap(action.payload.events);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTableEventsByLocationId.fulfilled, (state, action) => {
            state.events = action.payload;
            state.eventsByStatus = createTableEventsMap(action.payload);
        })
    }
});

export const { updateTableEventsState } = tableEventsSlice.actions;

export default tableEventsSlice.reducer;

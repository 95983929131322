import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateUser, User, usersAPI } from "@lib/usersAPI";
import { useDispatch } from "react-redux";

export const fetchUsers = createAsyncThunk(
    'users/getAll',
    async () => {
        const users: Array<User> = await usersAPI.getAll();
        return users;
    });

export const addUser = createAsyncThunk(
    'users/add',
    async (user: CreateUser) => {
        const response = await usersAPI.addUser(user);

        // dispatch(fetchUsers());
        return response;
    }
)


const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {

        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.users = action.payload;
        });

    }
});

export default usersSlice.reducer;

export const { } = usersSlice.actions;

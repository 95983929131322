import { useJwt } from "@hooks/useJwt.hook";
import { handle401 } from "@lib/utils";

export interface Location {
    id?: string;
    name: string;
    tables: any[];
}

const baseUrl = `${process.env.API_URL}/locations`;

export const locationsAPI = {
    async fetchById(locationId): Promise<Location> {
        const token = useJwt();

        const response = await fetch(`${baseUrl}/${locationId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(handle401);

        return response.json();
    },

    async getAll(): Promise<Array<Location>> {

        const token = useJwt();

        const response = await fetch(`${baseUrl}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(handle401);

        return response.json();
    },

    async deleteLocation(locationId: string): Promise<void> {
        const token = useJwt();

        const response = await fetch(`${baseUrl}/${locationId}`, {
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(handle401);
    },

    async addLocation(location: Location): Promise<void> {
        const token = useJwt();

        const response = await fetch(`${baseUrl}`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(location),
        })
            .then(handle401);
    }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { locationsAPI } from "@lib/locationsAPI";

export const fetchLocations = createAsyncThunk(
    'locations/getAll',
    async () => {
        return await locationsAPI.getAll();
    }
)

const locationsSlice = createSlice({
    name: 'locations',
    initialState: {
        locations: []
    },
    reducers: {

    },
    extraReducers: (builder) => {

        builder.addCase(fetchLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
        });

    }
});

export default locationsSlice.reducer;

export const {} = locationsSlice.actions;

import '../styles/globals.css'
import { Provider } from "react-redux";
import Notifications from "@features/notifications/Notifications";
import store from "../store/store";
import { useEffect } from "react";

function MyApp({ Component, pageProps }) {
    // Register service worker
    useEffect(() => {
        window.addEventListener("load", () => {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.register("/service-worker.js");
            }
        });
    });

    useEffect(() => {
        console.log('%cSoftware designed and developed by Khaled Muwahed and mudassir.dev. Visit https://mudassir.dev for enquiries.', 'background-color: #000; color: #fff; padding: 10px; border-radius: 8px;')
    }, []);

    return (
        <>
            <Provider store={store}>
                <Component {...pageProps} />
                <Notifications />
            </Provider>
        </>
    );
}

export default MyApp

import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { Color } from "@material-ui/lab";

export interface Notification {
    id?: string;
    severity: Color;
    message: string;
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: []
    },
    reducers: {
        addNotification: {
            reducer(state, action: PayloadAction<Notification>) {
                state.notifications.push(action.payload);
            },
            prepare(severity, message) {
                return {
                    payload: {
                        id: nanoid(),
                        severity,
                        message,
                    }
                }
            }
        },
        removeNotification(state, action: PayloadAction<string>) {
            state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
        }
    }
});

export default notificationsSlice.reducer;

export const { addNotification, removeNotification } = notificationsSlice.actions;

import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "@features/notifications/notificationsSlice";
import { useEffect } from "react";
import { RootState } from "@store/store";

const Notifications = () => {
    const notifications = useSelector((state: RootState) => state.notifications.notifications);

    const DISMISS_TIME = 3000;

    const notificationsSeen = new Set<string>();

    // Implements auto-dismiss of notifications
    useEffect(() => {
        notifications.forEach(({id}) => {
            if (notificationsSeen.has(id)) {
                return;
            }

            notificationsSeen.add(id);

            window.setTimeout(() => {
                dispatch(removeNotification(id));
            }, DISMISS_TIME);
        });
    }, [notifications]);

    const dispatch = useDispatch();

    const dismiss = (id: string) => {
        dispatch(removeNotification(id));
    }

    return (
        <>
            {notifications?.length !== 0 && <div className="fixed left-0 bottom-0 p-8 w-full flex justify-center">
                <div className="grid grid-cols-1 space-y-4 max-w-4xl w-full">
                    {notifications.map(notification => <Alert key={notification.id}
                                                              onClick={() => dismiss(notification.id)}
                                                              variant="filled"
                                                              severity={notification.severity}>{notification.message}</Alert>)}
                </div>
            </div>}
        </>
    );
};

export default Notifications;

import * as jwt from "jsonwebtoken";
import { JwtPayload } from "jsonwebtoken";

export const TOKEN_KEY = 'token';

export const useJwt = () => {

    if (typeof window === 'undefined') {
        console.log('we are running on the client');
        return null;
    }

    const token: string = localStorage.getItem(TOKEN_KEY);

    return token;
};

interface YaraJwtPayload extends JwtPayload {
    name: string;
    email: string;
    locations: Array<string>;
    picture_url: string;
    role: 'ADMIN' | 'MANAGER';
}

export const useJwtPayload = (): YaraJwtPayload => {

    const token = useJwt();

    if (token === null) {
        return null; // not logged in
    }

    return jwt.decode(token, { complete: true }).payload as YaraJwtPayload;
}

export const useIsAdmin = (): boolean => {
    const jwtPayload = useJwtPayload();

    return jwtPayload?.role === 'ADMIN';
}

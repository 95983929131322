import { useJwt } from "@hooks/useJwt.hook";
import { Tab } from "@material-ui/core";
import { handle401 } from "@lib/utils";

export enum TableEventStatus {
    DEFAULT = 'DEFAULT',
    FIRE_UNCONFIRMED = 'FIRE_UNCONFIRMED',
    FIRE_CONFIRMED = 'FIRE_CONFIRMED',
    COUNTDOWN_INIT = 'COUNTDOWN_INIT',
    RESET = 'RESET',
}

export interface TableEventDto {
    status: TableEventStatus;
}

export interface TableEventResponseDto {
    _id: string;
    locationId: string;
    tableId: string;
    tableName: string;
    status: TableEventStatus;
    transitionChangedAt: string;
}

const baseUrl = `${process.env.API_URL}/locations`;

export const tableEventsAPI = {

    async getEventsAtLocation(locationId: string): Promise<Array<TableEventResponseDto>> {
        const token = useJwt();

        const response = await fetch(`${baseUrl}/${locationId}/events`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(handle401);

        return response.json();

    },

    async createEvent(locationId: string, tableId: string, event: TableEventDto): Promise<void> {
        const token = useJwt();

        const response = await fetch(`${baseUrl}/${locationId}/tables/${tableId}/event`, {
            method: 'post',
            body: JSON.stringify(event),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(handle401);

    },

}
